.ChoiceSeparator {
  position: relative;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.ChoiceSeparator hr {
  background: none;
  border: none;
  border-bottom: 1px solid black;
  width: 10em;
  margin: 0;
}

.ChoiceSeparator div {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  line-height: 2em;
  text-align: center;
}

.ChoiceSeparator div span {
  display: inline-block;
  background: white;
  padding: 0 1em;
}
