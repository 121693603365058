.PaymentTypes {
  margin-bottom: 2em;
}

.PaymentTypeOption {
  display: flex;
  padding-bottom: 0.35em;
  font-weight: bold;
  align-items: center;
}

.PaymentTypeOption:not(:first-child) {
  padding-top: 0.35em;
}

.PaymentTypeOption input {
  margin-right: 0.5em;
}

.PaymentTypeOption span {
  display: block;
  flex: 1;
}

.PaymentTypeOption .PaymentTypeLogo {
  flex: 0;
  display: flex;
  align-items: center;
}

.PaymentTypeOption .PaymentTypeLogo img {
  display: block;
}
