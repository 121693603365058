.SummaryTable {
  width: 100%;
  margin-bottom: 2em;
}

.SummaryTable td {
  padding: 2px 0;
}

.SummaryTable td:last-child {
  text-align: right;
  padding-left: 10px;
}

.SummaryTable tbody tr:last-child td {
  padding-bottom: .35em;
}

.SummaryTable tbody + tfoot td {
  border-top: 1px solid lightgrey;
  font-weight: bold;
  padding-top: .35em;
}
